import * as React from 'react';
import {useState} from 'react';
import {i18n} from "../config/i18n";
import {ISystem} from "../interfaces";

interface Props {
    locale: string;
    systems: ISystem[];
    errors?: string[];
}

const BatchFileUpload: React.FC<Props> = ({locale, systems, errors}) => {
    const [selectedSystem, setSelectedSystem] = useState(systems[0]);
    const catalogs = selectedSystem.catalog_versions
    const systemsById = Object.fromEntries(systems.map(x => [x.id, x]));
    i18n.locale = locale;

    return (
        <>
            <h1>{i18n.t('batch_group_title')}</h1>
            <form encType="multipart/form-data" action={"/batch_files?locale=" + locale} acceptCharset="UTF-8"
                  method="post">
                {errors && errors.length > 0 && (
                    <div className="alert alert-warning">
                        <h2>{i18n.t('import_not_possible')}</h2>
                        {errors.map((msg, index) => (
                            <React.Fragment key={index}>
                                {msg}<br/>
                            </React.Fragment>
                        ))}
                    </div>
                )}
                <input type="hidden" name="authenticity_token"
                       value={document.querySelector("meta[name='csrf-token']").getAttribute("content")}
                       autoComplete="off"/>
                <div className="form-group row">
                    <label className="col-lg-2 col-form-label">
                        {i18n.t('system_selection')}
                    </label>
                    <div className="col-lg-6">
                        <select
                            name="batch_file[system_id]"
                            id="batch_file_system_id"
                            className="form-control form-select"
                            value={selectedSystem.id}
                            onChange={(e) => setSelectedSystem(systemsById[e.target.value])}
                        >
                            {systems.filter(x => !x.name.includes('LKAAT')).map(system => (
                                <option key={system.id} value={system.id}>{system.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div dangerouslySetInnerHTML={{__html: i18n.t('batchgrouper_form_intro' + (selectedSystem.is_simulation_grouper ? '_simulation' : ''), {version: selectedSystem.name})}}/>
                    </div>
                </div>
                <hr></hr>

                <div className="form-group row">
                    <label className="col-lg-2 col-form-label">
                        {i18n.t('format')}
                    </label>
                    <div className="col-lg-6">
                        <select className="form-control form-select" name="batch_file[format]" id="batch_file_format">
                            { selectedSystem.is_simulation_grouper &&
                                <option value="data_collection_2022">{i18n.t('data_collection_format')}</option>
                            }
                            <option value="batchgrouper">Batchgrouper</option>
                        </select>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-lg-2">
                        {i18n.t('file_upload_input')}
                    </div>
                    <div className="col-lg-6">
                        <input type="file" className="form-control" name="batch_file[batch_file]"
                               id="batch_file_batch_file"/>
                    </div>
                </div>
                <h4>{i18n.t('batchgrouper_form_step1')}</h4>
                <div className="form-group row">
                    <label className="col-lg-2 col-form-label">
                        {selectedSystem.is_simulation_grouper ? 'SimCasemaster' : 'Casemaster'}
                    </label>
                    <div className="col-lg-6">
                        <div className="form-check">
                            <input type="checkbox" name="do_case_splitting" id="do_case_splitting" value="1"
                                   className="form-check-input"/>
                            <div className="form-check-label"
                                 dangerouslySetInnerHTML={{__html: i18n.t('casesplitting_description_batchgrouper_form' + (selectedSystem.is_simulation_grouper ? '_simulation' : ''))}}/>
                        </div>
                    </div>
                </div>

                <h4>{i18n.t('batchgrouper_form_step2')}</h4>
                <div className="form-group row">
                    <div className="col-lg-5">
                        {i18n.t('group_with', {version: selectedSystem.name})}
                    </div>
                    <div className="col-lg-4">
                        <div className="row">
                            <div className="col-lg-3 fw-bold">ICD:</div>
                            <div className="col-lg-6">{catalogs?.icd}</div>
                        </div>
                        {selectedSystem.is_simulation_grouper ?
                            <div className="row">
                                <div className="col-lg-3 fw-bold">TARMED:</div>
                                <div className="col-lg-6">{catalogs?.tarmed}</div>
                            </div> :
                            <div className="row">
                                <div className="col-lg-3 fw-bold">{i18n.t('service_catalog')}:</div>
                                <div className="col-lg-6">{catalogs?.service_catalog}</div>
                            </div>
                        }
                    </div>
                </div>

                { selectedSystem.is_simulation_grouper ?
                    <>
                        <h4>{i18n.t('batchgrouper_form_step3_sim')}</h4>
                        <div className="form-group row">
                            <label className="col-lg-2 col-form-label">
                                {i18n.t('transcoding')}
                            </label>
                            <div className="col-lg-6">
                                <div className="form-check">
                                    <input type="checkbox" name="do_transcode" id="do_transcode" value="1"
                                           className="form-check-input" defaultChecked/>
                                    <div className="form-check-label">
                                        {i18n.t('transcoding_description_batchgrouper_form')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> :
                    <>
                        <h4>{i18n.t('batchgrouper_form_step3_application')}</h4>
                        <div className="form-group row">
                            <div className="col-lg-6 col-lg-offset-2">
                                {i18n.t('mapper_description_batchgrouper_form')}
                            </div>
                        </div>
                    </>
                }

                <div className="form-group row">
                    <div className="col-lg-6 col-lg-offset-2">
                        <button type="submit" className="btn btn-primary">
                            {i18n.t('group')}
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
        ;
};

export default BatchFileUpload;
